import { size, colors, typography, mediaQueries } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

type ContainerProps = {
  reducedCard: boolean;
  addBorder: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  background-color: ${colors.white};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ reducedCard }) =>
    reducedCard
      ? `${size.sm}px ${size.sm}px ${size.sm}px`
      : `${size.xl2}px ${size.lg}px`};
  ${({ addBorder }) =>
    addBorder &&
    css`
      border: 1px solid ${colors.teal2};
      border-radius: 4px;
    `};

  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.xl2}px ${size.lg}px;
  }
`;

export const Title = styled.h3<{ reducedCard: boolean }>`
  ${typography.h4Text};
  font-weight: ${typography.weight.bold};
  margin-top: ${size.lg}px;
  text-align: center;
  margin-bottom: ${size.xs1}px;
  ${({ reducedCard }) =>
    reducedCard &&
    css`
      ${typography.subtitle};
      margin-top: ${size.md}px;

      ${mediaQueries.forTabletHorizontalUp} {
        ${typography.h4Text};
        font-weight: ${typography.weight.bold};
      }
    `}

  /** To override typography color */
  color: ${colors.gray4} !important;
`;

export const Headline = styled.p<{ reducedCard: boolean }>`
  ${typography.h3Text};
  ${({ reducedCard }) =>
    reducedCard &&
    css`
      ${typography.h4Text};
      font-weight: ${typography.weight.bold};

      ${mediaQueries.forTabletHorizontalUp} {
        ${typography.h3Text};
      }
    `}
`;

export const SubHeadline = styled.p`
  ${typography.captionText};
  color: ${colors.gray4};
  margin-bottom: ${size.xs1}px;
`;

export const Description = styled.div`
  width: 100%;

  p {
    ${typography.bodyTextSmall};
    color: ${colors.gray4};
  }

  table {
    width: 100%;
    margin-bottom: ${size.md}px;

    tr {
      margin-top: ${size.md}px;
      display: flex;
      justify-content: space-between;

      th,
      td {
        ${typography.subtitleSmall};
        font-weight: ${typography.weight.book};
        text-align: left;
      }
    }
  }
`;

export const SvgIcon = styled.img<{ reducedCard: boolean }>`
  width: 92px;
  height: 92px;
  ${({ reducedCard }) =>
    reducedCard &&
    css`
      width: 45px;
      height: 45px;

      ${mediaQueries.forTabletHorizontalUp} {
        width: 92px;
        height: 92px;
      }
    `}
`;

export const HorizontalLine = styled.hr`
  border: none;
  border-bottom: 1px solid ${colors.green2};
  width: 100%;
  margin-top: ${size.md}px;
`;
