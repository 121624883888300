import { size } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  ul {
    list-style: none;

    li {
      margin-bottom: ${size.xl1}px;
    }
  }
`;
