import { size } from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { ContentSection } from 'utils/types';

import * as S from './InfoCard.styles';

export type InfoCardProps = Pick<
  ContentSection,
  'id' | 'image' | 'title' | 'headline' | 'subHeadline' | 'description'
> & {
  reducedCard?: boolean;
  addBorder?: boolean;
};

/**
 * Card with icons and text fields for a content section from contentful
 */
function InfoCard({
  image,
  title,
  headline,
  subHeadline,
  description: {
    childMarkdownRemark: { html: descriptionAsHtml },
  },
  reducedCard = false,
  addBorder = false,
}: InfoCardProps): JSX.Element {
  return (
    <S.Container reducedCard={reducedCard} addBorder={addBorder}>
      {image.gatsbyImageData ? (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.description}
          style={{ width: `${size.xl4}px`, height: `${size.xl4}px` }}
        />
      ) : (
        <S.SvgIcon
          src={image.file?.url}
          alt={image.description}
          reducedCard={reducedCard}
        />
      )}

      {title && <S.Title reducedCard={reducedCard}>{title}</S.Title>}
      {headline && (
        <S.Headline reducedCard={reducedCard}>{headline}</S.Headline>
      )}
      {subHeadline && <S.SubHeadline>{subHeadline}</S.SubHeadline>}

      <S.HorizontalLine />

      {descriptionAsHtml ? (
        <S.Description
          dangerouslySetInnerHTML={{
            __html: descriptionAsHtml,
          }}
        ></S.Description>
      ) : null}
    </S.Container>
  );
}

export default InfoCard;
