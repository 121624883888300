import { colors } from '@everlywell/leaves';
import loadable from '@loadable/component';
import CalloutBanner from 'components/CalloutBanner';
import { ComparisonTableProps } from 'components/ComparisonTable';
import GeneralDisclaimer, {
  DisclaimerDataProps,
} from 'components/GeneralDisclaimer';
import Layout from 'components/Layout';
import { SEO } from 'components/SEO';
import CardsWithActionContainer from 'containers/CardsWithActionContainer';
import DigitalResultsContainer from 'containers/DigitalResultsContainer';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { APP_ROOT, STORE_ROOT } from 'utils/constants/urls';
import { RESULTS_ROOT } from 'utils/constants/urls';
import { toKebabCase } from 'utils/helpers';
import { Promo } from 'utils/helpers/activePromoHelpers';
import { useProductBySlug, useProductVariants } from 'utils/hooks';
import { CategoryPageSectionType, SectionBackgroundType } from 'utils/types';

import HeroCategorySection from '../containers/HeroCategorySection';
import { PrePurchaseModalContextProvider } from './../contexts/PrePurchaseModal';

// Lazyly loaded components
const HowItWorksSection = loadable(
  () => import('../containers/HowItWorksContainer'),
);
const ProductCategorySection = loadable(
  () => import('../containers/ProductCategorySection'),
);
const HowItWorksWithTabs = loadable(
  () => import('../containers/HowItWorksWithTabs'),
);
const ComparisonTable = loadable(() => import('components/ComparisonTable'));

const FAQSection = loadable(() => import('../containers/FAQContainer'));

const RecommendedArticlesSection = loadable(
  () => import('../containers/Homepage/RecommendedArticlesContainer'),
);

const DescriptiveList = loadable(
  () => import('../containers/DescriptiveListContainer'),
);

type Props = {
  pageContext: {
    advertisedPromos?: Promo[];
    pageSlug: string;
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords?: string;
    canonicalUrl?: string;
    digDeeperSections?: Array<{ question: string; answer: { answer: string } }>;
    sections?: CategoryPageSectionType[];
    comparisonTable?: ComparisonTableProps;
    showFDADisclaimer?: boolean;
    disclaimer: DisclaimerDataProps;
  };
};

const PageContainer = styled.div<{ backgroundType?: SectionBackgroundType }>`
  background-color: ${({ backgroundType }) =>
    backgroundType === SectionBackgroundType.FullWidth ? colors.teal1 : ''};
`;

/**
 * Check if the given url is pointing to store prod, and translate it properly to
 * the current environment
 *
 * @examples
 *  QA env
 *  https://secure.everlywell.com/telehealth/sessions/new -> https://secure-qa.everlywell.com/telehealth/sessions/new
 *
 *  Staging env
 *  https://secure.everlywell.com/telehealth/sessions/new -> https://secure-staging.everlywell.com/telehealth/sessions/new
 *
 *  Relative url
 *  /virtual-care-visits -> /virtual-care-visits
 *
 *
 * @param {string} url
 * @returns {string}
 */
const replaceRedirectionLinkRoot = (url: string): string => {
  if (url.startsWith('https://secure.everlywell.com')) {
    return url.replace(/^https:\/\/secure.everlywell\.com/, STORE_ROOT);
  }

  if (url.startsWith('https://results.everlywell.com/')) {
    return url.replace(/^https:\/\/results.everlywell\.com/, RESULTS_ROOT);
  }

  return url;
};

// If used as url in a CTA, the CTA should toggle the disclaimer
const DISCLAIMER_SELECTOR = '#disclaimer';

const CategoryTemplate = (props: Props): JSX.Element => {
  const productVariants = useProductVariants();
  const productsBySlug = useProductBySlug(productVariants);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

  const {
    pageSlug: slug,
    metaTitle,
    metaDescription,
    metaKeywords,
    canonicalUrl,
    sections,
    advertisedPromos,
    digDeeperSections,
    comparisonTable,
    showFDADisclaimer = false,
    disclaimer,
  } = props.pageContext;

  useEffect(() => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        Page: 'Category Page',
        slug,
      },
    });
  }, [slug]);

  const toggleDisclaimer = () => {
    setIsDisclaimerOpen(!isDisclaimerOpen);
  };

  const [clickedFaqId, setClickedFaqId] = useState('');

  const getPageComponent = (section: CategoryPageSectionType) => {
    const cta = section.cta
      ? {
          ...section.cta,
          url: replaceRedirectionLinkRoot(section.cta.url ?? ''),
        }
      : undefined;

    const onCtaClick = (event: SyntheticEvent) => {
      if (cta?.url === DISCLAIMER_SELECTOR) {
        event.preventDefault();
        toggleDisclaimer();
      }
    };

    switch (section.componentName) {
      case 'HowItWorksSection': {
        return (
          <HowItWorksSection
            content={{
              title: section.title,
              headline: section.headline,
              listOfContentSections: section.listOfContentSections,
              noNumberTags: section.noNumberTags,
              sectionId: section.sectionId,
              isImageOnTheSide: section.isImageOnTheSide,
              cta: section.cta,
              disclaimerText: section.modalButtonText,
            }}
            onCtaClick={onCtaClick}
          />
        );
      }
      case 'ProductCategorySection': {
        return (
          <PrePurchaseModalContextProvider>
            <ProductCategorySection
              content={{
                headline: section.headline,
                headlineIcon: section.headlineIcon,
                subHeadline: section.subHeadline,
                cta: section.cta,
                productSection: section.productsSection,
                advertisedPromos: advertisedPromos,
              }}
              cardTrackingLabel={ANALYTICS.CATEGORY_PAGE.PRODUCT_CART_CLICK}
              addToCartTrackingLabel={ANALYTICS.CATEGORY_PAGE.ADD_TO_CART}
            />
          </PrePurchaseModalContextProvider>
        );
      }
      case 'HowItWorksSectionWithTabs': {
        return (
          <HowItWorksWithTabs
            content={{
              title: section.title,
              listOfContentSections: section.listOfContentSections,
              noNumberTags: section.noNumberTags,
              backgroundType: section.backgroundType,
            }}
          />
        );
      }
      case 'HeroCategorySection': {
        return (
          <HeroCategorySection
            content={{
              headline: section.headline,
              description: section.description,
              desktopImage: section?.desktopImages && section.desktopImages[0],
              mobileImage: section?.mobileImages && section.mobileImages[0],
              bulletPoints: section.bulletPoints,
              cta,
              disclaimerText: section.modalButtonText,
            }}
            onCtaClick={onCtaClick}
            setClickedFaqId={(faq) => setClickedFaqId(faq)}
          />
        );
      }
      case 'ComparisonTable': {
        if (comparisonTable) {
          return <ComparisonTable {...comparisonTable} slug={slug} />;
        } else {
          return null;
        }
      }
      case 'DigitalResultsSection': {
        return (
          <DigitalResultsContainer
            content={{
              title: section.title || '',
              headline: section.headline || '',
              desktopImages: section.desktopImages || [],
              mobileImages: section.mobileImages || [],
              listOfContentSections: section.listOfContentSections || [],
              backgroundType: section.backgroundType,
              description: section.description,
              disclaimer: section.modalButtonText,
              cta,
            }}
            onCtaClick={onCtaClick}
          />
        );
      }
      case 'RecommendedArticlesSection': {
        return (
          <RecommendedArticlesSection
            content={{
              title: section.title,
              headline: section.headline,
              listOfContentWithImages: section.listOfContentWithImages,
              mainCta: section.cta,
            }}
          />
        );
      }
      case 'CalloutBanner': {
        return (
          <CalloutBanner
            content={{
              title: section.title || '',
              desktopImages: section.desktopImages || [],
              mobileImages: section.mobileImages || [],
              cta: section.cta,
              description: section.description?.description,
            }}
            onCtaClick={onCtaClick}
          />
        );
      }
      case 'CardsWithActionSection':
        return (
          <CardsWithActionContainer
            content={{
              title: section.title,
              headline: section.headline,
              cta,
              disclaimerText: section.modalButtonText,
              subHeadline: section.subHeadline,
              description: section.description,
              cards: section.listOfContentSections,
            }}
            onCtaClick={(event) => {
              const { title, headline } = section;

              ewAnalytics.track({
                event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                data: {
                  sectionHeader: title || headline || 'No header provided',
                  contentfulSection: 'CardsWithActionSection',
                  ctaText: cta?.text,
                },
              });

              onCtaClick(event);
            }}
          />
        );
      case 'DescriptiveList': {
        return (
          <DescriptiveList
            sectionId={section.sectionId}
            title={section.title}
            headline={section.headline}
            description={section.description}
            cta={section.cta}
            listOfContentSections={section.listOfContentSections}
            onCtaClick={(faq) => setClickedFaqId(faq)}
          />
        );
      }
      default:
        return null;
    }
  };

  const faqSchema = digDeeperSections?.map((section) => ({
    question: section.question,
    answer: section.answer.answer,
  }));

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords?.replace('no-index', '')}
        url={`${APP_ROOT}/${slug}/`}
        faqSchema={faqSchema}
        slug={slug}
        canonicalUrl={canonicalUrl}
        noIndex={metaKeywords?.includes('no-index')}
      />
      <Layout
        productsBySlug={productsBySlug}
        showFDADisclaimer={showFDADisclaimer}
      >
        {/* Dynamic sections from contentful */}
        {sections?.map((section: CategoryPageSectionType, index: number) => {
          const pageComponent = getPageComponent(section);

          if (!pageComponent) {
            return null;
          }

          const pageContainerId = toKebabCase(section.sectionId);

          return (
            <PageContainer
              backgroundType={section.backgroundType}
              id={pageContainerId}
              key={`category-page-section-${index}`}
            >
              {pageComponent}
            </PageContainer>
          );
        })}
        {digDeeperSections?.length ? (
          <FAQSection
            content={{
              title: 'Questions',
              digDeeperSections: digDeeperSections,
            }}
            clickedFaqId={clickedFaqId}
            setClickedFaqId={setClickedFaqId}
          />
        ) : null}
        {disclaimer ? (
          <GeneralDisclaimer
            body={disclaimer.body}
            body2={disclaimer.body2}
            body3={disclaimer.body3}
            callToAction={
              disclaimer.callToAction && {
                text: disclaimer.callToAction.text,
                url: replaceRedirectionLinkRoot(disclaimer.callToAction.url),
              }
            }
            callToAction2={
              disclaimer.callToAction2 && {
                text: disclaimer.callToAction2.text,
                url: replaceRedirectionLinkRoot(disclaimer.callToAction2.url),
              }
            }
            title={disclaimer.title}
            open={isDisclaimerOpen}
            openHandler={() => toggleDisclaimer()}
          />
        ) : null}
      </Layout>
    </>
  );
};

export default CategoryTemplate;
