import {
  mediaQueries,
  size,
  typography,
  Container as LeavesContainer,
  H1,
  Button,
  breakpoints,
  colors,
} from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const SMALL_IMAGE_HEIGHT = '400px';
const DESKTOP_HEIGHT = '535px';

const Container = styled(LeavesContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;

  /* Non mobile-first to override leaves styles
    Remove all padding and make container span the whole page
  */
  ${mediaQueries.forTabletHorizontalDown} {
    padding: 0 !important;
    width: 100% !important;
  }

  ${mediaQueries.forDesktopUp} {
    flex-direction: row;
    min-height: ${DESKTOP_HEIGHT};

    > div {
      width: 50%;
    }
  }

  ${mediaQueries.forUltraWideDesktopUp} {
    max-width: ${breakpoints.forUltraWideDesktopUp}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Headline = styled(H1)`
  ${typography.h2Text};

  ${mediaQueries.forTabletVerticalDown} {
    font-size: 2.566rem;
    line-height: 3.5rem;
    letter-spacing: 0.51px;
    text-align: left;
  }
`;

const HeroContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  /* Same as leaves container */
  padding: ${size.lg}px;

  ${mediaQueries.forDesktopUp} {
    padding: 0;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.forTabletVerticalUp} {
    align-items: flex-start;
  }

  ${mediaQueries.forDesktopUp} {
    width: 419px;
    padding: ${size.lg}px ${size.xl1}px ${size.xl4}px 0;
  }
`;

const DescriptionText = styled.p`
  ${typography.bodyTextSmall};
  margin: ${size.md}px 0 ${size.lg}px;

  ${mediaQueries.forDesktopUp} {
    ${typography.bodyText};
  }

  a {
    color: ${colors.green4};
    text-decoration: none;

    :hover,
    :active {
      text-decoration: underline;
    }
  }
`;

const ButtonWrapper = styled(Button)`
  width: 327px;
  margin-top: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: ${size.xl1}px;
  }
`;

const BulletContent = styled.div`
  align-self: baseline;

  > div > ul > li {
    margin-bottom: ${size.md}px;
  }
`;

const BulletIcon = styled(GatsbyImage)`
  width: 25px;
  margin-right: ${size.xs1}px;
`;

const BulletText = styled.div`
  > div > p {
    ${typography.buttonText}
  }
`;

const HeroImageWrapper = styled.div`
  ${mediaQueries.forDesktopUp} {
    position: absolute;
    right: 0;
    align-self: flex-start;
  }
`;

const HeroImage = styled(GatsbyImage)<{ desktopHeight: number }>`
  width: 100%;
  border-radius: 0 0 0 80px;
  height: ${SMALL_IMAGE_HEIGHT};

  ${mediaQueries.forTabletVerticalUp} {
    border-radius: 0 0 0 150px;
    height: ${DESKTOP_HEIGHT};
  }

  ${mediaQueries.forDesktopUp} {
    height: ${({ desktopHeight }) => `${desktopHeight}px` || DESKTOP_HEIGHT};
  }

  ${mediaQueries.forBigDesktopUp} {
    width: 80%;
  }

  ${mediaQueries.forUltraWideDesktopUp} {
    width: 60%;
  }
`;

export const DisclaimerText = styled.span`
  ${typography.captionText};
  margin-top: ${size.lg}px;
`;

export {
  Container,
  Headline,
  HeroContent,
  HeroContentWrapper,
  DescriptionText,
  ButtonWrapper,
  HeroImageWrapper,
  HeroImage,
  BulletContent,
  BulletIcon,
  BulletText,
};
