import InfoCard, { InfoCardProps } from 'components/InfoCard';
import TrackVisibilityWrapper from 'components/TrackVisibilityWrapper';
import React from 'react';
import { sanitize } from 'utils/domHelpers';
import { CTA } from 'utils/types';

import * as S from './CardsWithActionContainer.styles';

export type CardsWithActionContainerProps = {
  content: {
    title?: string;
    headline?: string;
    subHeadline?: string;
    cards?: InfoCardProps[];
    disclaimerText?: string;
    description?: {
      childMarkdownRemark?: {
        html: string;
      };
    };
    // To avoid changing Contentful due to the re-architecture, this will use the Link content model from Contentful.
    cta?: CTA;
  };
  onCtaClick?: React.MouseEventHandler<HTMLButtonElement>;
};

/**
 * Cards with action. A group of cards with a CTA. Can be used for an action
 */
export default function CardsWithActionContainer({
  content: {
    title,
    headline,
    subHeadline,
    disclaimerText,
    description,
    cta,
    cards,
  },
  onCtaClick,
}: CardsWithActionContainerProps): JSX.Element {
  return (
    <TrackVisibilityWrapper
      label={title || 'No Label Provided - CardsWithActionSection'}
    >
      <S.Container>
        <S.MainContent>
          <S.TopWrapper>
            <S.Title>{title}</S.Title>
            <S.Headline>{headline}</S.Headline>
            <S.DesktopCta
              appearance="primary"
              // @ts-ignore TODO: Temporary ts-ignore to not block a deploy.
              onClick={onCtaClick}
              href={cta?.url}
            >
              {cta?.text}
            </S.DesktopCta>
          </S.TopWrapper>

          <S.CardWrapper>
            {cards?.map((card) => (
              <InfoCard key={card.id} {...card} />
            ))}
          </S.CardWrapper>
        </S.MainContent>

        <S.ExtraInfoWrapper>
          {disclaimerText ? (
            <S.LinkWrapper
              dangerouslySetInnerHTML={{
                __html: sanitize(disclaimerText),
              }}
            ></S.LinkWrapper>
          ) : null}
          <S.HorizontalLine />
          {subHeadline && <S.SubHeadline>{subHeadline}</S.SubHeadline>}

          {description?.childMarkdownRemark && (
            <S.DisclaimerText
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
          )}
        </S.ExtraInfoWrapper>

        <S.MobileCta
          appearance="primary"
          // @ts-ignore TODO: Temporary ts-ignore to not block a deploy.
          onClick={onCtaClick}
          href={cta?.url}
        >
          {cta?.text}
        </S.MobileCta>
      </S.Container>
    </TrackVisibilityWrapper>
  );
}
