import { OneImageDisplay } from 'components/DigitalResultsSection/OneImageDisplay';
import TrackVisibilityWrapper from 'components/TrackVisibilityWrapper';
import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { sanitize } from 'utils/domHelpers';
import { CTA, ImageType } from 'utils/types';

import * as S from './styles';

export interface CalloutBannerProps {
  content: {
    title: string;
    desktopImages: ImageType[];
    mobileImages: ImageType[];
    cta?: CTA;
    description?: string;
  };
  onCtaClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const CalloutBanner: React.FC<CalloutBannerProps> = (props) => {
  const {
    content: { title, desktopImages, mobileImages, cta, description },
    onCtaClick,
  } = props;

  const uid = 'calloutBanner';

  const hasOnlyOneDesktopImage = desktopImages.length === 1;
  const hasOnlyOneMobileImage = mobileImages.length === 1;
  const hasOnlyOneImage = hasOnlyOneDesktopImage || hasOnlyOneMobileImage;

  const handleCTAClick: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    if (onCtaClick) {
      onCtaClick(event);
    }

    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        sectionHeader: title,
        contentfulSection: uid,
        ctaText: cta?.text,
      },
    });
  };

  return (
    <S.Wrapper>
      <TrackVisibilityWrapper label={ANALYTICS.CATEGORY_PAGE.CALLOUT_BANNER}>
        <S.Container>
          <S.StyledRow center="xs">
            <S.MobileTitle>{title}</S.MobileTitle>
            <OneImageDisplay
              data-test-id={`${uid}-image-container`}
              desktopImages={desktopImages}
              mobileImages={mobileImages}
              hasOnlyOneImage={hasOnlyOneImage}
            />

            <S.StyledCol xs={12} md={5}>
              <S.ContentSection>
                <S.DesktopTitle>{title}</S.DesktopTitle>

                {description ? (
                  <S.Description
                    dangerouslySetInnerHTML={{
                      __html: sanitize(description),
                    }}
                  />
                ) : null}

                {cta ? (
                  <S.CTA
                    data-testid={`${uid}-cta`}
                    href={cta.url}
                    appearance="primary"
                    // @ts-ignore TODO: Temporary ts-ignore to not block a deploy.
                    onClick={handleCTAClick}
                  >
                    {cta.text}
                  </S.CTA>
                ) : null}
              </S.ContentSection>
            </S.StyledCol>
          </S.StyledRow>
        </S.Container>
      </TrackVisibilityWrapper>
    </S.Wrapper>
  );
};

// Export ---------

export default React.memo(CalloutBanner);
