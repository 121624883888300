import {
  Container as RawContainer,
  size,
  mediaQueries,
  colors,
  Button,
  Col,
  Row,
  H3,
  typography,
} from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import styled from 'styled-components';

interface WrapperProps {
  backgroundColor?: 'Primary' | 'Secondary';
}

export const Wrapper = styled(SectionWrapper)<WrapperProps>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor === 'Primary' ? colors.white : colors.teal1};
`;

export const Container = styled(RawContainer)``;

export const StyledRow = styled(Row)``;

export const StyledCol = styled(Col)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ContentSection = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    padding-left: ${size.xl2}px;
  }
`;

export const MobileTitle = styled(H3)`
  margin-bottom: ${size.sm}px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const DesktopTitle = styled(H3)`
  display: none;

  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
  }
`;

export const CTA = styled(Button)`
  margin-top: ${size.md}px;
  min-width: 150px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: ${size.xl1}px;
  }
`;

export const Description = styled.div`
  margin-top: ${size.md}px;
  ${typography.captionText};

  a {
    color: ${colors.green4};
    text-decoration: none;
    font-weight: ${typography.weight.xbold};

    :hover,
    :active {
      text-decoration: underline;
    }
  }
`;
