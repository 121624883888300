import React from 'react';
import { BulletPoint as BulltePointType } from 'utils/types';

import BulletPoint from './BulletPoint/BulletPoint';
import * as S from './BulletPoints.styles';

export type BulletPointsProps = {
  bulletPoints: BulltePointType[];
  onClick?: (bulletText: string, hyperlinkId: string) => void;
};

/**
 * Bullet point list with icons
 */
export default function BulletPoints({
  bulletPoints,
  onClick,
}: BulletPointsProps): JSX.Element {
  return (
    <S.Container>
      <ul>
        {bulletPoints.map((bullet) => (
          <li key={bullet.id}>
            <BulletPoint bulletPoint={bullet} onClick={onClick} />
          </li>
        ))}
      </ul>
    </S.Container>
  );
}
