import {
  size,
  typography,
  mediaQueries,
  Container as LeavesContainer,
  Button,
  colors,
} from '@everlywell/leaves';
import styled from 'styled-components';

const TOP_CONTENT_WIDTH = 300;
const MAIN_CONTENT_GAP = size.lg;

export const Container = styled(LeavesContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${size.xl1}px;
  padding-bottom: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: ${size.xl4}px;
    padding-bottom: ${size.xl4}px;
  }
`;

export const Title = styled.p`
  ${typography.overlineText};
  margin-bottom: ${size.sm}px;
  text-transform: uppercase;
  font-weight: ${typography.weight.xbold};
  text-align: center;

  ${mediaQueries.forTabletHorizontalUp} {
    text-align: left;
  }
`;

export const Headline = styled.h2`
  ${typography.h3Text}
  text-align: center;
  margin-bottom: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    text-align: left;
  }
`;

export const TopWrapper = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    width: ${TOP_CONTENT_WIDTH}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size.lg}px;
  flex: 1;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
  }
`;

export const SubHeadline = styled.p`
  ${typography.bodyTextSmall};
  color: ${colors.gray4};
`;

export const DisclaimerText = styled.div`
  ${typography.captionText};
`;

export const LinkWrapper = styled.div`
  a {
    color: ${colors.green4};
    text-decoration: none;

    :hover,
    :active {
      text-decoration: underline;
    }
  }
`;

export const ExtraInfoWrapper = styled.div`
  margin: ${size.lg}px 0;
  text-align: center;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-left: calc(${TOP_CONTENT_WIDTH}px + ${MAIN_CONTENT_GAP}px);
    padding: 0 ${size.xl4}px;
  }
`;

export const HorizontalLine = styled.hr`
  border: none;
  border-bottom: 1px solid ${colors.green4};
  width: 50%;
`;

export const MobileCta = styled(Button)`
  min-width: 150px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const DesktopCta = styled(Button)`
  display: none;

  ${mediaQueries.forTabletHorizontalUp} {
    display: inline;
    width: fit-content;
    min-width: 150px;
  }
`;

export const MainContent = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    gap: ${MAIN_CONTENT_GAP}px;
  }
`;
