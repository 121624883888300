import { colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  ${typography.subtitle};

  .bullet-icon {
    margin-right: ${size.sm}px;
  }

  .anchor-text {
    p {
      color: ${colors.green4};
      font-weight: ${typography.weight.bold};

      &:hover {
        color: ${colors.green3};
      }
    }
  }

  .no-target {
    p {
      color: ${colors.gray4};
    }
  }

  .has-hyperlink {
    a {
      color: ${colors.green4};
      font-weight: ${typography.weight.bold};
      text-decoration: none;
    }
  }
`;

export const SvgIcon = styled.img`
  width: ${size.lg}px;
  height: ${size.lg}px;
`;
