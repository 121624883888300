import { size } from '@everlywell/leaves';
import AnchorLink from 'components/AnchorLink';
import RichTextWrapper from 'components/RichTextWrapper';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useRef } from 'react';
import { BulletPoint as BulletPointType } from 'utils/types';

import * as S from './BulletPoint.styles';

export type BulletPointProps = {
  bulletPoint: BulletPointType;
  onClick?: (bulletText: string, hyperlinkId: string) => void;
};

/**
 * A single bullet point with an icon and a rich text
 */
export default function BulletPoint({
  bulletPoint: { icon, text, target },
  onClick,
}: BulletPointProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);

  // Get the hyperlink target value that comes from Contentful
  const node = JSON.parse(text.raw.toString());
  const content = node.content[0].content;
  const hyperlink = content.find(
    (content: any) => content.nodeType === 'hyperlink',
  );
  const hasHyperlink = !!hyperlink;
  const hyperlinkId = hyperlink?.data.uri;

  const onClickContainer = () => {
    const bulletText = containerRef.current?.innerText || 'undefined';
    if (bulletText && onClick) {
      onClick(bulletText, hyperlinkId);
    }
  };

  return (
    <S.Container onClick={onClickContainer} ref={containerRef}>
      {icon.gatsbyImageData ? (
        <GatsbyImage
          image={icon.gatsbyImageData}
          alt={icon.description}
          style={{ width: `${size.lg}px`, height: `${size.lg}px` }}
          className="bullet-icon"
        />
      ) : (
        <S.SvgIcon
          src={icon.file?.url}
          alt={icon.description}
          className="bullet-icon"
        ></S.SvgIcon>
      )}
      {target ? (
        <div className={hasHyperlink ? 'has-hyperlink' : 'anchor-text'}>
          {hasHyperlink ? (
            <RichTextWrapper contentfulRichText={text} />
          ) : (
            <AnchorLink href={`#${target}`} offset="40">
              <RichTextWrapper contentfulRichText={text} />
            </AnchorLink>
          )}
        </div>
      ) : (
        <div className="no-target">
          <RichTextWrapper contentfulRichText={text} />
        </div>
      )}
    </S.Container>
  );
}
