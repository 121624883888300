import BulletPoints from 'components/BulletPoints';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { sanitize } from 'utils/domHelpers';
import { BulletPoint, ImageType, CTA } from 'utils/types';

import * as S from './styles';

export type HeroCategorySectionProps = {
  content: {
    headline?: string;
    description?: {
      description: string;
    };
    desktopImage?: ImageType;
    mobileImage?: ImageType;
    bulletPoints?: BulletPoint[];
    // To avoid changing Contentful due to the re-architecture, this will use the Link content model from Contentful.
    cta?: CTA;
    // To avoid any confusion - this disclaimerText prop is actually coming from the modalButtonText in Contentful
    disclaimerText?: string;
  };
  onCtaClick?: React.MouseEventHandler<HTMLButtonElement>;
  setClickedFaqId?: (faq: string) => void;
};

export default function HeroCategorySection({
  content,
  onCtaClick,
  setClickedFaqId,
}: HeroCategorySectionProps): JSX.Element | null {
  const [leftSideHeight, setLeftSideHeight] = useState<number>(0);
  const leftSideRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setLeftSideHeight(leftSideRef.current?.clientHeight || 0);
  }, [leftSideRef]);

  if (!content) return null;

  const {
    headline,
    description,
    desktopImage,
    bulletPoints,
    cta,
    disclaimerText,
  } = content;

  const onBulletClick = (bulletText: string, hyperlinkId: string) => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_LINK,
      data: {
        label: bulletText,
      },
    });
    // Set the bullet point's anchor link FAQ ID when clicked
    // so it will scroll to the correct FAQ accordion
    // and the accordion will open to the FAQ's answer
    if (hyperlinkId && setClickedFaqId) {
      setClickedFaqId(hyperlinkId);
    }
  };

  const handleCtaClick: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        sectionHeader: headline,
        contentfulSection: 'HeroCategorySection',
        ctaText: cta?.text,
      },
    });

    onCtaClick?.(event);
  };

  return (
    <S.Container>
      <S.HeroContentWrapper ref={leftSideRef}>
        <S.HeroContent>
          <S.Headline>{headline}</S.Headline>
          <S.DescriptionText>
            {parse(sanitize(description?.description || ''))}
          </S.DescriptionText>
          <S.BulletContent>
            {bulletPoints ? (
              <BulletPoints
                bulletPoints={bulletPoints}
                onClick={onBulletClick}
              />
            ) : null}
          </S.BulletContent>
          {cta ? (
            <S.ButtonWrapper
              href={cta.url}
              appearance="primary"
              // @ts-ignore TODO: Temporary ts-ignore to not block a deploy.
              onClick={handleCtaClick}
            >
              {cta.text}
            </S.ButtonWrapper>
          ) : null}
          <S.DisclaimerText>{disclaimerText}</S.DisclaimerText>
        </S.HeroContent>
      </S.HeroContentWrapper>
      <S.HeroImageWrapper>
        {desktopImage && (
          <S.HeroImage
            image={desktopImage.gatsbyImageData}
            alt={desktopImage.description}
            desktopHeight={leftSideHeight}
          />
        )}
      </S.HeroImageWrapper>
    </S.Container>
  );
}
